import React from 'react'
import PropTypes from 'prop-types'
import remark from 'remark';
import remarkHtml from 'remark-html';
import recommended from 'remark-preset-lint-recommended';
import { v4 } from 'uuid'
import { useStaticQuery, graphql } from "gatsby"

import Content, { HTMLContent } from '../components/Content'
import { getImage, getSocialMedia } from '../util'

export default function Sponsors(props) {
  const data = useStaticQuery(graphql`
    query SponsorsQuery {
      allFluxibleSponsorTypes {
        nodes {
          alternative_id
          name
          canPluralize
          showDescription
        }
      }
    }
  `)

  const sponsorTypes = data.allFluxibleSponsorTypes.nodes.reduce((acc, node) => {
    if (node.alternative_id !== null) {
      acc[node.alternative_id] = node
      acc[node.alternative_id].list = []
    }
    return acc;
  }, {})

  const sponsors = props.data

  if (!sponsors) return null

  sponsors.forEach(({ node: sponsor }) => {
    if (sponsor.name === '') return;

    const SponsorContent = HTMLContent || Content
    let socialMediaContent = (<div />);
    let website = '';
    const description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(sponsor.description).toString();

    if (sponsor.socialMedia) {
      sponsor.socialMedia.map(medium => {
        if (medium.type === 'website') {
          website = medium.value
        }
        return null;
      })
      socialMediaContent = getSocialMedia(sponsor.socialMedia, false, sponsor.name);
    }

    let jsx = sponsorTypes[sponsor.sponsorTypeId].showDescription ? (
      <article key={v4()} className="fluxible-supporter">
        {sponsor.logo &&
          <figure>
            <a href={website}>
              {getImage(sponsor.logo, sponsor.name, "supporter-logo")}
            </a>
          </figure>}
        {description && <SponsorContent content={description} />}
        {socialMediaContent}
      </article>
    ) :
      sponsor.logo &&
      (<a href={website} key={v4()}>
        {getImage(sponsor.logo, sponsor.name, "supporter-logo-inline")}
      </a>
      );

    if (props.template === 'homepage') {
      jsx = (<a href={website} className={`sponsor-item  sponsor-item--${sponsor.sponsorTypeId}`} key={v4()}>
        {getImage(sponsor.logo, sponsor.name)}
      </a>);
    }

    sponsor.sponsorTypeId && sponsorTypes[sponsor.sponsorTypeId].list.push(jsx);
  })

  return (
    <div className="grid-x sponsors-list">
      {
        Object.keys(sponsorTypes).map((key) => {
          const list = sponsorTypes[key].list

          return (
            <div key={v4()} className={`cell small-12 medium-8 medium-offset-2 ${sponsorTypes[key].showDescription ? '' : 'text-center'}`}>
              {list.length > 0 && props.template !== 'homepage' &&
                <h4 className="supporter-level-header">
                  {`${sponsorTypes[key].name}${list.length > 1 && sponsorTypes[key].canPluralize ? 's' : ''}`}
                </h4>
              }
              {list.length > 0 && props.template === 'homepage' &&
                <h3 className="supporter-level-header">
                  {`${sponsorTypes[key].name}${list.length > 1 && sponsorTypes[key].canPluralize ? 's' : ''}`}
                </h3>
              }
              {key === 'supporters' ? (
                <div className="text-center">
                  {list}
                </div>
              ) : list}
            </div>
          )
        })
      }
    </div>
  )
}

Sponsors.propTypes = {
  data: PropTypes.array,
}
