import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Sponsors from '../components/sponsors'

class EditionSponsorsPage extends React.Component {
  render() {
    const { data } = this.props

    if (!data || !data.sponsors) return null;

    const { edges: sponsors } = data.sponsors
    const edition = data.edition

    return (
      <Layout>
        <section className="section grid-container">
          <Helmet title={`Sponsors | ${edition.name} `} />
          <h1 className="page-header">{edition.name} Sponsorship</h1>
          <p className="sponsors-paragraph">
            Sincere thanks to our sponsors for supporting Canada’s UX community. As a volunteer-run event, 100% of these sponsorship contributions go towards making Fluxible a success.
            {/*Some sponsorship opportunities are still available, so
            <a href="https://www.fluxible.ca/wp-content/uploads/2020/01/Fluxible-2020-Sponsorships.pdf"> download this sponsorship PDF </a>
            and inquire if you’re interested.*/}
          </p>
          <Sponsors data={sponsors} isTv={edition.editionTypeId === 2} edition={edition} />
        </section>
      </Layout>
    )
  }
}

EditionSponsorsPage.propTypes = {
  data: PropTypes.shape({
    allFluxibleSponsors: PropTypes.shape({
      edges: PropTypes.array,
    }),
    fluxibleEditions: PropTypes.object,
  }),
}

export default EditionSponsorsPage

export const editionSponsorsQuery = graphql`
  query EditionSponsorsPageQuery($editionId: Int!) {
    sponsors: allFluxibleSponsors(
      filter: { editionId: { eq: $editionId } }
    ) {
      edges {
        node {
          name
          description
          sponsorTypeId
          edition {
            name
          }
          logo {
            name
            altText
            contentType
          }
          socialMedia {
            type
            value
          }
          fields {
            slug
          }
        }
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $editionId }) {
      name
      startDate
      endDate
      editionTypeId
    }
  }
`
